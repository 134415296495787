import React, { useState } from "react"

import { validatorTypes } from "../../hooks/forms/forms"
import useCaptcha from "../../hooks/forms/useCaptcha"
import useInput from "../../hooks/forms/useInput"
import Captcha from "../Form/Captcha"
import Form from "../Form/Form"
import Input from "../Form/Input"
import Submit from "../Form/Submit"
import TextArea from "../Form/TextArea"
import s from "./FeedbackForm.module.scss"

export default function FeedbackForm({
  captchaDisabled,
  emailLabel,
  langCode,
  locale,
  messageLabel,
  nameLabel,
  onSubmit,
  phoneNumberLabel,
  sendingLabel,
  submitLabel,
}) {
  const [loading, setLoading] = useState(false)
  const [errorsVisible, setErrorsVisible] = useState(false)
  const captcha = useCaptcha(errorsVisible)
  const fields = {
    name: useInput({ initialValue: "", errorsVisible, required: true }),
    email: useInput({
      initialValue: "",
      errorsVisible,
      required: true,
      validatorType: validatorTypes.email,
    }),
    message: useInput({ initialValue: "", errorsVisible, required: true }),
    phoneNumber: useInput({
      initialValue: "",
      errorsVisible,
      required: false,
      validatorType: validatorTypes.phoneNumber,
    }),
    captcha: captchaDisabled ? null : captcha,
  }

  return (
    <Form className={s.form} onSubmit={handleSubmit}>
      <Input
        className={s.name}
        label={nameLabel}
        name="name"
        {...fields.name.props}
      />
      <Input
        className={s.email}
        label={emailLabel}
        name="email"
        {...fields.email.props}
      />
      <Input
        className={s.phoneNumber}
        label={phoneNumberLabel}
        name="phoneNumber"
        type="tel"
        {...fields.phoneNumber.props}
      />
      <TextArea
        className={s.message}
        label={messageLabel}
        name="message"
        {...fields.message.props}
      />
      {captchaDisabled ? null : (
        <Captcha
          className={s.captcha}
          {...fields.captcha.props}
          langCode={langCode}
        />
      )}
      <Submit
        className={s.submit}
        disabled={loading}
        value={loading ? sendingLabel : submitLabel}
      />
    </Form>
  )

  function handleSubmit(event) {
    event.preventDefault()

    if (isFormValid()) {
      submitForm(new FormData(event.target))
    } else {
      setErrorsVisible(true)
    }
  }

  function submitForm() {
    const now = Date.now()

    setLoading(true)
    resetForm()
    onSubmit({
      // order is important, don't change it
      name: fields.name.value,
      email: fields.email.value,
      message: fields.message.value,
      locale,
      timestamp: new Date(now).toISOString(),
      phoneNumber: fields.phoneNumber.value,
    })
  }

  function isFormValid() {
    return Object.values(fields)
      .filter((field) => field !== null)
      .every((field) => field.valid && field)
  }

  function resetForm() {
    Object.values(fields)
      .filter((field) => field !== null)
      .forEach((field) => field.reset && field.reset())
  }
}
