import { graphql } from "gatsby"
import { navigate } from "gatsby"
import React from "react"

import FeedbackForm from "../components/FeedbackForm/FeedbackForm"
import Hero, { colors, titleColors, variations } from "../components/Hero/Hero"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import {
  selectFeedbackPageContent,
  selectProductGroups,
  selectTranslations,
} from "../graphql/selectors"
import { sendFeedbackForm } from "../service/rest"
import { createLocalizedPagePath } from "../utils/urls"
import s from "./feedback.module.scss"

export default function FeedbackPage(props) {
  const { data, pageContext } = props
  const { langCode, locale, localePath, pagePathsByLangCode } = pageContext
  const translations = selectTranslations(data)
  const { seo, hero, form } = selectFeedbackPageContent(data)

  return (
    <Layout
      className={s.feedbackPage}
      i18n={{ ...translations, localePath }}
      langCode={langCode}
      pagePathsByLangCode={pagePathsByLangCode}
      productGroups={selectProductGroups(data)}
    >
      <SEO {...seo} lang={langCode} />
      <Hero
        color={colors.grenadier}
        title={hero.title}
        titleColor={titleColors.bright}
        variation={variations.wideBottomPadding}
      />
      <div className={s.container}>
        <div className={s.formHolder}>
          <FeedbackForm
            emailLabel={form.emailLabel}
            langCode={langCode}
            locale={locale}
            messageLabel={form.messageLabel}
            nameLabel={form.nameLabel}
            onSubmit={handleFormSubmit}
            phoneNumberLabel={form.phoneNumberLabel}
            sendingLabel={form.sendingLabel}
            submitLabel={form.submitLabel}
          />
        </div>
      </div>
    </Layout>
  )

  function handleFormSubmit(formData) {
    sendFeedbackForm(formData).then(
      redirectToSuccessPage,
      redirectToSuccessPage
    )
  }

  function redirectToSuccessPage() {
    navigate(
      createLocalizedPagePath(
        translations.pages,
        localePath,
        "/feedback-success/"
      )
    )
  }
}

export const query = graphql`
  query FeedbackPage($locale: String) {
    ...FeedbackPageFragment
    ...LayoutFragment
    ...TranslationsFragment
  }
`
